import InviteModal from "@components/InviteModal";
import { RequestPageBanner } from "@components/RequestPageComponents";
import TRLs from "@components/TRLs";
import toast from "@components/Toast";
import { Button, Icon, PageContainer, Tag, TextLink, Tooltip } from "@components/library";
import RequestAttachmentsContainer from "@components/library/Attachments/RequestAttachmentsContainer";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { useSearchParams } from "@hooks/router";
import { fetchCurrentUser } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getRfpForRfpPage } from "@requests/rfps";
import { marketplacePath } from "@routes/utils";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RfpStatus } from "@tsTypes/rfps";
import { getArrayFromRubyRange, track } from "@utils/appUtils";
import { getRfpStatus } from "@utils/rfpUtils";
import { fixMarkdownNewlines, renderMarkdownText } from "@utils/textUtils";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import RequestDiscussion from "../../components/RequestDiscussion/RequestDiscussion";
import PreviewRfpPageBanner from "./PreviewRfpPageBanner";
import RfpCtaContainer from "./RfpCtaContainer";
import RfpKeywordsContainer from "./RfpKeywordsContainer";
// @ts-ignore
import { RfpPageRequest } from "@tsTypes/index";
import { UserRole } from "@tsTypes/users";
import { t } from "@utils/i18n";
import RequestFAQs from "../../components/RequestFAQs";
import AboutCompany from "../../components/RequestPageComponents/AboutCompany";
import AccessCode from "./AccessCode";
import AccessCodeBanner from "./AccessCodeBanner";
import Benefits from "./Benefits";

interface Props {
  asPreview?: boolean;
  rfpPreviewData?: RfpPageRequest;
  isResponsive?: boolean;
}

export const ACCESS_CODE_PARAM = "access_code";

const RfpPage = ({ asPreview = false, rfpPreviewData, isResponsive = true }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [request, setRequest] = useState<RfpPageRequest>(rfpPreviewData ?? ({} as RfpPageRequest));
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);

  const [needsAccessCode, setNeedsAccessCode] = useState(false);
  const [accessCodeError, setAccessCodeError] = useState("");
  const [accessCodeRequestData, setAccessCodeRequestData] = useState<{
    company_name: string;
    request_title: string;
  }>();

  const rfp = (rfpPreviewData ?? request).requestable;
  const company = request.company;

  const rfpDiscussionRef = useRef<HTMLDivElement>(null);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const dispatch = useDispatch();
  const { identifier, previewHash } = useParams();
  const history = useHistory();
  const { fromHref } = history.location;
  const [searchParams] = useSearchParams();
  const accessCodeParam = searchParams.get(ACCESS_CODE_PARAM);

  const fetchRequest = async (code?: string) => {
    try {
      // RFP Page
      if (!asPreview && !rfpPreviewData) {
        const { request: rfpRequest } = await getRfpForRfpPage(identifier, code ?? accessCodeParam);

        if (!rfpRequest.id) {
          history.push("/");
        }

        document.title = `${rfpRequest.title} | ${rfpRequest.requestable.rfp_program?.name} | Halo`;

        dispatch(fetchCurrentUser());

        track(SegmentEventName.View, {
          request_id: rfpRequest.id,
          requestable: {
            requestable_id: rfpRequest.id,
            requestable_type: rfpRequest.requestable_type,
          },
          react_component: "RfpPage",
        });

        if (searchParams.get("saved")) {
          toast.success(
            <p>
              Saved! You can find your draft in&nbsp;
              <Link to="/my_proposals" style={{ textDecoration: "underline", color: "white" }}>
                My proposals
              </Link>
              .
            </p>
          );
        }

        if (code) {
          searchParams.set(ACCESS_CODE_PARAM, code);
          history.replace({ search: searchParams.toString() });
        }
        setNeedsAccessCode(false);

        setRequest(rfpRequest);
        setIsLoading(false);
        return;
      }

      // RFP Preview Page
      if (asPreview && !rfpPreviewData) {
        const decodedRfpIdentifier = atob(previewHash);

        const { request: rfpRequest } = await getRfpForRfpPage(decodedRfpIdentifier, previewHash);

        if (!rfpRequest.id) {
          history.push("/");
        }

        setRequest(rfpRequest);
        setIsLoading(false);
        return;
      }

      // RFP Form Preview
      if (asPreview && rfpPreviewData) {
        setIsLoading(false);
      }
    } catch (e) {
      if (e.response.status === 401) {
        if (needsAccessCode) {
          setAccessCodeError("Access code incorrect.");
        } else {
          setNeedsAccessCode(true);
          setAccessCodeRequestData(e.response.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  useLayoutEffect(() => {
    let animationFrameId: number;

    const checkAndScroll = () => {
      if (window.location.hash === "#qa" && rfpDiscussionRef.current) {
        rfpDiscussionRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        animationFrameId = requestAnimationFrame(checkAndScroll);
      }
    };

    checkAndScroll();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [rfpDiscussionRef]);

  if (needsAccessCode) {
    return (
      <AccessCode
        onSubmit={fetchRequest}
        error={accessCodeError}
        requestData={accessCodeRequestData}
      />
    );
  }

  if (isLoading) {
    return <Loader inline="centered" />;
  }

  const showRfpDiscussionBanner =
    getRfpStatus(request) !== RfpStatus.IN_EXTENSION && rfp.is_discussion_enabled;
  const trlRange = rfp.trl_range ? getArrayFromRubyRange(rfp.trl_range) : null;

  const shouldShowAccessCodeBanner =
    !asPreview &&
    request.visibility.none &&
    request.access_code &&
    (currentUser.role === UserRole.SPONSOR || currentUser.is_admin);

  const isConfidential = request?.confidential || rfpPreviewData?.confidential;

  return (
    <>
      <InviteModal
        open={isShareModalOpen}
        requestId={request.id}
        closeModal={() => setIsShareModalOpen(false)}
        trackAndToastHandler={() => toast.success("RFP shared!")}
        inviteMessage=""
        inviteUrl="/notifications/share_rfp"
        modalHeader={`Share this opportunity with ${
          currentUser.role === 2 ? "faculty" : "colleagues"
        }`}
        modalSubheader={`Your ${
          currentUser.role === 2 ? "faculty" : "colleague(s)"
        } will receive an email with a link to this opportunity`}
        inviteSubject={`${currentUser.first_name} ${currentUser.last_name} shared a funding opportunity with you.`}
        typeOfInvite="shareRFP"
      />
      {asPreview && !rfpPreviewData && <PreviewRfpPageBanner />}
      {shouldShowAccessCodeBanner && <AccessCodeBanner accessCode={request.access_code!} />}
      <Container asPreview={asPreview} isResponsive={isResponsive} data-testid="rfp-page-main">
        <PageContainer hasPaddingTop={asPreview} hasTopDivider>
          {!asPreview && (
            <TextLink
              to={fromHref ?? marketplacePath("requests")}
              font={FONTS.MEDIUM_2}
              margin="24px 0"
            >
              <Icon name="Chevron Left" margin="0 0 0 -8px" />
              Back to Opportunities
            </TextLink>
          )}
          <RequestPageBanner request={request} asPreview={asPreview} />
          <MainContent isResponsive={isResponsive}>
            <div>
              {showRfpDiscussionBanner && (
                <QuestionCTA isResponsive={isResponsive}>
                  <div>
                    <Heading>What questions do you have about this RFP?</Heading>
                    <div>
                      Get them answered by the team at{" "}
                      {!isConfidential && "company_name" in company
                        ? company.company_name
                        : "the company"}
                      .
                    </div>
                  </div>
                  <Button
                    width="max-content"
                    onClick={() => {
                      rfpDiscussionRef.current?.scrollIntoView({ behavior: "smooth" });
                      if (rfp.is_discussion_enabled && currentUser.role === 0) {
                        setIsCommenting(true);
                      }
                    }}
                  >
                    Ask your question
                  </Button>
                </QuestionCTA>
              )}
              <Heading>{t("pages.rfp_page.headings.background")}</Heading>
              <div data-testid="rfp-background">
                {renderMarkdownText(fixMarkdownNewlines(rfp.background))}
              </div>
              <Heading>{t("pages.rfp_page.headings.looking_for")}</Heading>
              <BulletedSection data-testid="rfp-looking-for">
                {renderMarkdownText(rfp.looking_for)}
              </BulletedSection>
              <RequestAttachmentsContainer
                heading={<Subheading>{t("pages.rfp_page.headings.attachments")}</Subheading>}
                attachments={request.request_attachments}
              />
              {/* These fields are stored as strings, and the first 3 characters just render a bullet point */}
              {Boolean(request.request_solutions?.length) && (
                <>
                  <Subheading>{t("pages.rfp_page.headings.solutions_of_interest")}</Subheading>
                  <BulletedSection data-testid="rfp-solutions-of-interest">
                    {renderMarkdownText(
                      request.request_solutions.map((solution) => `* ${solution.name}`).join("\n")
                    )}
                  </BulletedSection>
                </>
              )}
              {Boolean(request.request_requirements?.length) && (
                <>
                  <Subheading>{t("pages.rfp_page.headings.must_have_requirements")}</Subheading>
                  <BulletedSection data-testid="rfp-must-have-requirements">
                    {renderMarkdownText(
                      request.request_requirements
                        .map((requirement) => `* ${requirement.description}`)
                        .join("\n")
                    )}
                  </BulletedSection>
                </>
              )}

              {(rfp.preferred_requirements?.length ?? 0) > 2 && (
                <>
                  <Subheading>{t("pages.rfp_page.headings.preferred_requirements")}</Subheading>
                  <BulletedSection data-testid="rfp-nice-to-have-requirements">
                    {renderMarkdownText(rfp.preferred_requirements)}
                  </BulletedSection>
                </>
              )}
              {(rfp.out_of_scope?.length ?? 0) > 2 && (
                <>
                  <Subheading>{t("pages.rfp_page.headings.out_of_scope")}</Subheading>
                  <BulletedSection data-testid="rfp-out-of-scope">
                    {renderMarkdownText(rfp.out_of_scope)}
                  </BulletedSection>
                </>
              )}
              {trlRange ? (
                <TRLsContainer>
                  <Subheading>
                    {t("pages.rfp_page.headings.trl")}
                    <Tag
                      content={
                        <TrlTagContent>
                          {trlRange[0] === trlRange[1]
                            ? `Level ${trlRange[0]}`
                            : `Levels ${trlRange[0]}-${trlRange[1]}`}
                          <Tooltip
                            content="Technology readiness levels help describe the maturity of solutions. An organization may seek solutions that fall only within a specified range of TRLs."
                            tooltipWidth="410px"
                          >
                            <Icon name="Info" size="xs" color={COLORS.NEUTRAL_400} />
                          </Tooltip>
                        </TrlTagContent>
                      }
                      size="md"
                      margin="0 0 0 16px"
                    />
                  </Subheading>
                  <TRLs trlRange={trlRange} />
                </TRLsContainer>
              ) : null}
              <Benefits
                partnershipModels={rfp.partnership_models ?? []}
                fundingBenefits={request.funding_benefits}
                nonMonetaryBenefits={request.non_monetary_benefits}
                isResponsive={isResponsive}
              />
              {!isConfidential && <AboutCompany request={request} isResponsive={isResponsive} />}
              {rfp.is_discussion_enabled && (
                <RequestDiscussion
                  request={request}
                  ref={rfpDiscussionRef}
                  isCommenting={isCommenting}
                  setIsCommenting={setIsCommenting}
                  setIsShareModalOpen={setIsShareModalOpen}
                />
              )}
            </div>
            <RightColumn>
              <RfpCtaContainer
                request={request}
                asPreview={asPreview}
                setIsShareModalOpen={setIsShareModalOpen}
              />
              <RfpKeywordsContainer request={request} />
              {request.visibility.any && <RequestFAQs request={request} />}
            </RightColumn>
          </MainContent>
        </PageContainer>
      </Container>
    </>
  );
};

export default RfpPage;

const Container = styled.div`
  pointer-events: ${({ asPreview }) => (asPreview ? "none" : "auto")};
  ${({ isResponsive }) => !isResponsive && `width: ${WINDOW_DIMENSIONS.BASE_WINDOW_WIDTH}px;`}
`;
const QuestionCTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  background: ${COLORS.BLUE_LIGHT_250};
  padding: 32px 64px 32px 48px;
  border-radius: 12px;
  ${FONTS.REGULAR_1}
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      ${WINDOW_DIMENSIONS.EXTRA_WIDE_MEDIA_QUERY} {
        width: max-content;
      }
  `}
`;
const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 190px;
  padding: 84px 0 100px;
  & p {
    ${FONTS.REGULAR_1};
    margin: 0;
  }
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        gap: 80px;
      }
      ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
        display: flex;
        flex-direction: column-reverse;
        gap: 64px;
      }
      ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
        padding: 84px 32px 100px;
      }
  `}
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const Heading = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 24px;
  &:not(:first-of-type) {
    margin-top: 64px;
  }
`;
const Subheading = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.SEMIBOLD_1};
  margin: 24px 0 8px;
`;
const BulletedSection = styled.div`
  & ul {
    padding-left: 4px;
  }
`;
// Used in RequestForStartupPage
export const TRLsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
`;
export const TrlTagContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
